import { defineStore } from 'pinia'
import VueJwtDecode from 'vue-jwt-decode'

import { request } from '@/request/request'
import { UserRequest } from '@/request/requests/user.request'
import router from '@/router'
import { getRoleFromToken } from '@/utilities/get-role-from-token'

export const useAuthorizationStore = defineStore('authorization', {
  state: () => ({
    form: { login: '', password: '' },
    data: {},
    isLoading: false,
    isEnterButtonDisabled: false,
    error: {
      title: 'loginErrorTitle',
      description: 'loginErrorDescription',
      isError: false,
    },
  }),
  actions: {
    async login() {
      // this.isLoading = true
      // this.isEnterButtonDisabled = false

      try {
        const res = await UserRequest.login()

        if (res.data) {
          localStorage.setItem('authSessionToken', JSON.stringify(res.data.authSessionToken))

          window.location.href = res.data.redirectURI.toString()
        }
      } catch (error) {
        this.isEnterButtonDisabled = true
      } finally {
        this.isLoading = false
      }
    },
    async refreshToken(refreshToken) {
      this.isLoading = true

      try {
        const res = await UserRequest.refreshToken(refreshToken)

        if (res?.data) {
          localStorage.setItem(
            'tokens',
            JSON.stringify({
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
              expiresIn: res.data.expires_in,
              expirationTime: Date.now() + res.data.expires_in * 1000,
            }),
          )

          request.setAuthToken(res.data.access_token)
          this.data = getRoleFromToken(VueJwtDecode.decode(res.data.access_token))
        }
      } catch (error) {
        await this.login()
        this.isEnterButtonDisabled = true
      } finally {
        this.isLoading = false
      }
    },
    async getTokens(state, code, authSessionToken) {
      this.isLoading = true

      try {
        const res = await UserRequest.getTokens(state, code, authSessionToken)

        if (res?.data) {
          request.setAuthToken(res.data.access_token)

          localStorage.setItem(
            'tokens',
            JSON.stringify({
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
              expiresIn: res.data.expires_in,
              expirationTime: Date.now() + res.data.expires_in * 1000,
            }),
          )
          this.data = getRoleFromToken(VueJwtDecode.decode(res.data.access_token))

          await router.replace({ query: {} })
          await router.push({ name: 'Home' })
        }
      } catch (error) {
        this.error.isError = true
      } finally {
        await router.replace({ query: {} })
        this.isLoading = false
      }
    },
    async logout() {
      this.isLoading = true

      try {
        const res = await UserRequest.logout()

        const { v4: uuidv4 } = require('uuid')

        const generatedState = uuidv4()

        localStorage.setItem('logoutState', JSON.stringify(generatedState))
        window.location.href = res.data + `&state=${generatedState}`
      } catch (err) {
        this.error.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
  getters: {},
})
