<template>
  <!--  <div v-if="!authorizationStore.isLoading" class="white-page" />-->
  <router-view />
  <RhsLoading class="loading-container" :is-loading="authorizationStore.isLoading" />
</template>

<script setup>
import { RhsLoading } from '@rhsolutions/design-system'
import { nextTick, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

import i18n from '@/config/i18n'
import { request } from '@/request/request'
import { useAuthorizationStore } from '@/stores/views/authorization.store'

const router = useRouter()

const authorizationStore = useAuthorizationStore()

const storedLocale = localStorage.getItem('locale')

if (storedLocale) {
  request.setAcceptLanguage(storedLocale)
}

onMounted(async () => {
  await nextTick()
})

watch(i18n.global.locale, (newLocale) => {
  localStorage.setItem('locale', newLocale)
  request.setAcceptLanguage(newLocale)

  router.go()
})
</script>

<style lang="scss">
@import 'assets/style/general';
@import '@/assets/style/variables.scss';

.white-page {
  width: 100vw;
  height: 100vh;

  color: $global-background;
}
</style>
