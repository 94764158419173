<template>
  <RhsHeaderApp>
    <template #right>
      <div class="header-buttons-wrapper">
        <div class="locale-changer">
          <GlobalSelectLanguage />
        </div>
        <RhsButton
          :label="$t('main.batches')"
          :disabled="isOnBatchesPage"
          :class="{ 'is-on-batches-page': isOnBatchesPage }"
          class="button-black"
          variant="tertiary"
          @click="onClickActionButton('Home')"
        />
        <RhsButton
          :label="$t('common.search')"
          :class="{ 'is-on-batches-page': isOnSearchPage }"
          :disabled="isOnSearchPage"
          class="button-black"
          variant="tertiary"
          @click="onClickActionButton('BatchesSearch')"
        />
        <RhsButton
          :label="$t('common.logout.menuButton')"
          class="button-red"
          variant="tertiary"
          @click="onClickActionButton(null, 'logout')"
        />
      </div>
    </template>
  </RhsHeaderApp>

  <main class="main-wrapper">
    <router-view />
  </main>
</template>

<script>
import { RhsButton, RhsHeaderApp } from '@rhsolutions/design-system'
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref } from 'vue'
import VueJwtDecode from 'vue-jwt-decode'
import { useRoute, useRouter } from 'vue-router'

import GlobalSelectLanguage from '@/components/Global/GlobalSelectLanguage.vue'
import { useAuthorizationStore } from '@/stores/views/authorization.store'
import { getRoleFromToken } from '@/utilities/get-role-from-token'

export default defineComponent({
  name: 'Layout',
  components: {
    GlobalSelectLanguage,
    RhsButton,
    RhsHeaderApp,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const authorizationStore = useAuthorizationStore()

    const storedCredentials = localStorage.getItem('tokens')
    const tokens = ref(JSON.parse(storedCredentials) || {})

    const onClickActionButton = async (path, action) => {
      if (!path && action === 'logout') await authorizationStore.logout()
      else await router.push({ name: path })
    }

    const isOnBatchesPage = computed(() => route.path === '/')
    const isOnSearchPage = computed(() => route.path === '/search')

    const checkTokenExpiration = async () => {
      if (tokens.value?.expiresIn && tokens.value?.refreshToken && tokens.value?.expirationTime) {
        authorizationStore.data = getRoleFromToken(VueJwtDecode.decode(tokens.value.accessToken))

        const currentTime = Date.now()

        const timeDifference = tokens.value?.expirationTime - currentTime

        if (timeDifference <= 3000) {
          await authorizationStore.refreshToken(tokens.value.refreshToken)
          tokens.value = JSON.parse(localStorage.getItem('tokens'))
        }
      }
    }

    let intervalId

    onMounted(async () => {
      await nextTick()

      await checkTokenExpiration()

      //TODO Засунул в request, возможно когда то тут пригодиться
      // if (tokens.value?.accessToken) request.setAuthToken(tokens.value?.accessToken)

      // console.log('authorizationStore', authorizationStore.data)

      intervalId = setInterval(checkTokenExpiration, 1000)
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    return {
      isOnBatchesPage,
      isOnSearchPage,
      onClickActionButton,
    }
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';

.main-wrapper {
  background-color: $black-5;
  min-height: calc(100vh - 81px);
}

.header-buttons-wrapper {
  display: flex;
  gap: 34px;

  .locale-changer {
    display: flex;
    align-items: center;
  }

  .rhs-flyouts {
    display: flex;
    justify-content: center;
  }

  button {
    font-size: 14px;
    font-weight: 500;
  }

  .is-on-batches-page {
    opacity: 0.3;
  }

  .button-black {
    color: $black;

    &:hover {
      opacity: 0.7;
    }
  }

  .button-red {
    color: $active-red;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
