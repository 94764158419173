import { request } from '@/request/request'

export class UserRequest {
  static async login() {
    const url = '/login'

    return await request.axios.get(url)
  }

  static async getTokens(state, code, authSessionToken) {
    const url = `/tokens?state=${state}&code=${code}`

    return await request.axios.get(url, {
      headers: { 'Auth-Session-Token': authSessionToken },
    })
  }

  static async refreshToken(refreshToken) {
    const url = '/refresh'

    return await request.axios.post(url, { refreshToken: refreshToken })
  }

  static async logout(params) {
    const url = '/logout'

    return await request.axios.post(url, params)
  }
}
