<template>
  <RhsFlyouts
    v-model="selectedLanguage"
    :items="
      $i18n.availableLocales.map((item) => {
        return {
          id: item,
          title: item.toUpperCase(),
        }
      })
    "
    size="s"
  />
</template>

<script>
import { RhsFlyouts } from '@rhsolutions/design-system'
import { defineComponent, ref, watch } from 'vue'

import i18n from '@/config/i18n'

export default defineComponent({
  name: 'GlobalSelectLanguage',
  components: { RhsFlyouts },
  setup() {
    const selectedLanguage = ref({ id: i18n.global.locale.value, title: i18n.global.locale.value.toUpperCase() })

    watch(
      () => selectedLanguage.value,
      () => {
        i18n.global.locale.value = selectedLanguage.value.id
      },
    )

    // const getFlagEmoji = (countryCode) => {
    //   return [...countryCode.toUpperCase()]
    //     .map((char) => String.fromCodePoint(127397 + char.charCodeAt()))
    //     .reduce((a, b) => `${a}${b}`)
    // }

    return { selectedLanguage }
  },
})
</script>

<style scoped lang="scss"></style>
