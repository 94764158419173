import { createRouter, createWebHistory } from 'vue-router'

import Layout from '@/layouts/Layout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import { useLayoutStore } from '@/stores/modules/layout.store'

const routes = [
  {
    path: '/code',
    name: 'CodeLayout',
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'Code',
        component: () => import('@/views/Code.vue'),
        meta: {
          title: `Страница обработки кода`,
        },
      },
    ],
  },
  {
    path: '/success-logout',
    name: 'LogoutLayout',
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'Logout',
        component: () => import('@/views/Logout.vue'),
        meta: {
          title: `Страница обработки выхода`,
        },
      },
    ],
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Batches.vue'),
        meta: {
          title: `Страница партий`,
        },
      },
      {
        path: 'batches/create',
        name: 'BatchesCreate',
        component: () => import('@/views/BatchesCreate.vue'),
        meta: {
          title: `Страница создания партии`,
        },
      },
      {
        path: 'batches/view/:id',
        name: 'BatchesView',
        component: () => import('@/views/BatchesView.vue'),
        meta: {
          title: `Страница просмотра партии`,
        },
      },
      {
        path: 'search',
        name: 'BatchesSearch',
        component: () => import('@/views/BatchesSearch.vue'),
        meta: {
          title: `Страница поиска партии`,
        },
      },
      {
        path: 'error',
        name: 'Error',
        component: () => import('@/views/Error.vue'),
        meta: {
          title: `Страница ошибки`,
        },
      },
    ],
  },
  { path: '/:catchAll(.*)', component: () => import('@/views/Error.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async () => {
  // очищаем ошибки перед переходом на другую страницу
  useLayoutStore().setError(null, null)
  useLayoutStore().errorModal = false
})

export default router
